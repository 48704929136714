/***********************************************************************************
                                  scroll bare
************************************************************************************/

/***********************************************************************************
                                  scroll bare
************************************************************************************/

.ReactModal__Overlay, .ReactModal__Overlay--after-open{
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 2
}
.ReactModal__Content, .ReactModal__Content--after-open{
  position: relative !important;
  inset: 0 0 0 68px !important;
  /* margin-top: 36px; */
  max-width: 500px;
  /* min-height: 164px; */
  max-height: 100%
}
@media (max-width: 900px) {
  .ReactModal__Content, .ReactModal__Content--after-open{
    inset: 0 !important;
  } 
}
.ReactModal__Content h5{
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 12px;
}
.ReactModal__Content div.form-group{margin-bottom: 1rem;}
.ReactModal__Content select.form-control-sm{height: calc(2.1rem + 2px) !important;}
.ReactModal__Content fieldset{ margin-bottom: 0;}
.ReactModal__Content .card-text{ font-size: 12px; font-weight: 300; color: #000;}
.form-control-position {
  line-height: 3.2rem;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
}
.has-icon-left .form-control-position {
  right: auto;
  left: 3px;
}
.has-icon-left .form-control {
  padding-right: 1.5rem;
  padding-left: -webkit-calc(2.75rem + 2px);
  padding-left: -moz-calc(2.75rem + 2px);
  padding-left: calc(2.75rem + 2px);
}
.nx-table-search .form-control-position {
  line-height: 2.4rem;
}
.nx-table-search .form-control {
  padding-top: 6px;
  padding-bottom: 6px;
  border-color: #ddd;
}
.nx-input-label{
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 200;
}
.nx-label{
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 200;
}
.btn, .form-control{
  font-size: 12px !important;
}
.btn-link{
  padding: 0px !important;
  margin: 0px 8px;
}
.card, .small-box, .elevation-4, .elevation-2{
  box-shadow: none !important;
}
.spinner-border{
  border: .2em solid #b53da2 !important;
  border-right-color: transparent !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  border-radius: 50%;
  -webkit-animation: spin .8s ease infinite;
  animation: spin .8s ease infinite;
}
.table th, .table td {
  padding: 8px !important;
  font-size: 12px !important;
}
.nx-add-ul{
  list-style: none;
}

.btn{
  line-height: 1 !important;
  font-weight: 400;
  padding: .65rem 1.5rem;
}

.nx-collapse-actions-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 1.25rem;
  align-items: flex-end;
}
.nx-collapse-input-container{
  display: flex;
  flex-direction: column;
  margin: 0 0.75rem;
  min-width: 200px;
}

.nx-collapse-input-container label{
  font-size: 12px;
  font-weight: 300 !important;
  text-transform: uppercase;
}
.nx-collapse-input-container select{
  height: 26px;
}

.nx-collapse-actions-btn{
  padding: .25rem .5rem;
  font-size: 10px !important;
  line-height: 1.5;
  height: 26px;
  text-transform: uppercase;
  font-weight: 400;
}
.nx-nav-toggle, .nx-nav-toggle:focus{
  background: transparent;
  border: none;
  outline: none;
}
.nx-nav-toggle:hover{
  opacity: 0.8;
  cursor: pointer;
}
.nx-nav-toggle i{
  font-size: 20px;
}